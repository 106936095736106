.focus-inwards-dark:focus-within {
	box-shadow: inset 0 0 0 0.14rem black !important;
}

.focus-outwards-dark:focus-within {
	box-shadow: 0 0 0 0.14rem black !important;
}

.focus-inwards-light:focus-within {
	box-shadow: inset 0 0 0 0.14rem white !important;
}

.focus-outwards-light:focus-within {
	box-shadow: 0 0 0 0.14rem white !important;
}
