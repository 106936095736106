/* You can add global styles to this file, and also import other style files */
@import "./scss/bundle";



.mat-icon {
	height: unset !important;
	width: unset !important;
}

.mat-mdc-option {
	font-size: 1em !important;
}

.mat-calendar-body {
	font-size: 1em !important;
}

.mat-calendar-table-header th {
	font-size: 0.8em !important;
}

.mat-body{
	font: 400 1em/1.25em Roboto, "Helvetica Neue", sans-serif;
}

.mat-h1{
	font-size: 1.4em !important;
}

.mat-h2, .mat-title, .mat-typography h2 {
	font: 500 1.43em/2.29em Roboto, "Helvetica Neue", sans-serif;
	line-height: inherit;
}

.mat-tooltip{
	font-size: 1em;
}

//Adjust radio-button size
.mdc-radio{
	padding:0 !important;

	.mat-mdc-radio-touch-target{
		width: 20px;
		height: 20px;
	}
	.mdc-radio__native-control{
		width: 20px;
		height: 20px;
	}
}

.mdc-form-field{
	label{
		cursor: pointer;
	}
}

//TODO unify hover-effect for checkboxes and radio-buttons
.mdc-checkbox {
	padding: 0 !important;

	.mat-mdc-checkbox-touch-target{
		width: 20px !important;
		height: 20px !important;
	}

	.mdc-checkbox__native-control{
		width: 20px !important;
		height: 20px !important;
	}

	.mdc-checkbox__background{
		position: initial !important;
	}
}


.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

//arrow indicating selected option in mat-mdc-select
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
	color:black;
}


//Remove default decoration elements for input-search-fields
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance:none;
}
