.l-nobr {
	white-space: nowrap;
	margin-right: 0.36em;
	display: inline-block;

	&--block {
		white-space: nowrap;
		display: block;
	}
}
