.c-entry {
	padding: 0.36em 0.79em;
	position: relative;
	line-height: 1.25;
	min-height: 1.93em;
	word-break: break-word;
	cursor: default;

	&--temporary {
		font-style: italic;
	}

	&--temporary-geroetet {
		text-decoration: underline;
		text-decoration-style: dashed;
		text-decoration-color: $color_orange;
	}

	&--geroetet {
		text-decoration: underline;
		text-decoration-color: $color_orange;
	}
}
