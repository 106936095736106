.c-areas {
	display: inline-flex;
	height: calc(100vh - 6.8em - 48px); // 6.8em = header height, 48px = Tabs height

	.c-areas__area {
		background-color: #fff;
		box-shadow: 0 0 0.43em 0.07em rgba(#000, 0.12);
		display: flex;
		margin: 2.29em 1.14em;
	}
}

.c-areas__area--accent {
	.x-section {
		&__headline,
		&__subline {
			color: #000;

			.c-button {
				color: #000;
				background-color: $color-mat-accent;
				border-color: $color-mat-accent;

				&:focus-within {
					border-color: #000;
				}

				&:hover,
				&:active {
					background-color: #000;
					border-color: #000;
					color: $color-mat-accent;
				}
			}
			.mat-mini-fab {
				background-color: transparent;
			}
			.mat-button-wrapper {
				color: #000;
			}
		}

		&__headline {
			background-color: $color-mat-accent-dark;
		}

		&__subline {
			background-color: $color-mat-accent;
		}
	}
}
