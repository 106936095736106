.c-editor {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;

	&__main {
		width: 100%;
		overflow: auto;
		max-height: 35.71em;
		padding-right: 0.36em;
		min-width: 26.14em;
	}

	> * {
		width: 100%;
	}

	&__column--small,
	&__column--50 {
		width: calc(50% - 0.86em);
	}

	&__column--50-force-new-line {
		width: calc(50% - 0.86em);
		margin-right: 50%;
	}

	&__column--30 {
		width: calc(30% - 0.86em);
	}

	&__sticky-right {
		width: 100%;
		position: sticky;
		padding-left: 1.43em;
		min-width: 26.14em;
		margin-top: -0.86em;
		overflow: auto;
		max-height: 35.71em;
	}

	&__result {
		position: relative;
		border: 0.07em solid rgba(0, 0, 0, 0.12);
		border-radius: 0.29em;
		padding: 1.29em 0.86em;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1.07em;
		min-height: 4.29em;

		&::before {
			position: absolute;
			background: #fff;
			top: -0.64em;
			left: 0.36em;
			font-size: 0.86em;
			color: rgba(#000, 0.6);
			padding: 0 0.5em;
		}

		span {
			color: rgba(#000, 0.8);
		}
	}

	.mat-error {
		white-space: nowrap;
		display: flex;
		align-items: center;
	}

	.mat-form-field-subscript-wrapper {
		overflow: visible;
	}
}
