*,
::before,
::after {
	background-repeat: no-repeat; /* 1 */
	box-sizing: border-box; /* 2 */
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	padding: 0;
	margin: 0;
	background: $color-background-grey;

	&.fixed-width {
		width: 134.71em;
		position: relative;
	}

	&.dialog-open nav {
		pointer-events: none;
	}
}

.text-muted {
	color: $color_darkgrey;
}

table {
	width: 100%;
}

.pointer {
	cursor: pointer;
}
