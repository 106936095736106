.c-containertable {

	&__table {
		border: 0.07em solid $color_darkgrey;
		border-radius: 0.71em;
		overflow: hidden;
		box-shadow: 0 0.07em 0.21em rgba(#000, 0.15);
		border-collapse: collapse;
		border-spacing: 0;
	}

	&__header-row {
		background-color: $color_middlegrey;
		line-height: 1;
		padding: 1.07em;
		border-bottom: 0.07em solid rgba($color_darkgrey, 0.5);

		&--small {
			padding: 0.5em 0.71em;
		}
	}

	.mat-header-row {
		background-color: $color_middlegrey;
	}

	.mat-cell {
		border-right: 0.07em solid $color_middlegrey;

		&:last-child {
			border-right: 0;
		}
	}

	.mat-header-cell {
		position: relative;

		&::after {
			display: block;
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 0.07em;
			height: 1.29em;
			background: rgba($color_darkgrey, 0.5);
		}

		&:last-child{
			&::after {
				display: none;
			}
		}
	}

	.mat-cell,
	.mat-header-cell {
		padding: 0.36em 1.07em;
	}
}
