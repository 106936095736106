.c-dialog {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 6.07em);
	min-width: 22.86em;

	&__headline {
		flex-grow: 0;
		flex-shrink: 0;
		margin: -1.71em -1.71em 0.86em -1.71em;
		padding: 1.14em 1.71em;
		cursor: grab;

		&:hover {
			background-color: rgba(#000, 0.04);
		}
	}

	&__editor {
		flex-grow: 1;
		overflow: auto;
		min-height: 0; /* for Firefox */
		position: relative;
		margin-right: -1.71em;
		margin-left: -1.71em;
		padding-right: 1.71em;
		padding-left: 1.71em;
	}

	&__button-bar {
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0 -0.86em;
		display: flex;
		justify-content: space-between;
	}
}

.mdc-dialog__content .mat-mdc-form-field:first-child {
	margin-top: 5px !important;
}

.mat-mdc-dialog-container {
	text-align: left;
	border: 0.07em solid rgba(#000, 0.24);

	.mat-mdc-dialog-actions {
		display: flex;
		justify-content: space-between;
		padding: 8px 0 8px 0;
		margin: 0.86em -0.86em -0.86em -0.86em;
		width: auto;
	}

	button {
		margin: 0.86em;
	}
}

.mat-mdc-dialog-title {
	font-size: 1.4em !important;
	margin-bottom: 20px !important;
	padding: unset !important;
}

.mat-mdc-dialog-content {
	padding: 0 1px 0 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
	color: rgba(0, 0, 0, 0.87) !important;

}

.mat-mdc-dialog-container .mdc-dialog__content {
	line-height: inherit !important;
	font-size: inherit !important;
	font-weight: inherit !important;
	letter-spacing: inherit !important;
}

.mat-mdc-dialog-surface {
	padding: 1.8em !important;
}

.mat-mdc-dialog-title::before {
	height: 0 !important;
}
