.c-verfuegung {

	&__headline {
		display: flex;
		align-items: center;
	}

	&__subline {
		margin: 0;
	}

	&__button-bar {
		margin: 0 -0.36em;

		button {
			margin: 0.36em;
		}
	}

	&__section {
		border-bottom: 0.07em solid $color_middlegrey;
		margin: 0 -0.71em;
		padding: 0 0.71em 1.43em 0.71em;
	}

	&__list {
		list-style: none;
		margin: 0 0 0.71em 0;
		padding: 0;

		p {
			margin: 0.71em;
		}

		mat-radio-group {
			display: flex;
			flex-direction: column;
			font-size: 0.85rem;
			line-height: 1.7;
			margin: 0.71em;
		}
	}

	&__listitem {
		display: flex;
		justify-content: flex-start;
		margin: 0.57em 0;

		button {
			margin: -0.57em;
		}

		ul {
			margin: 0;
			padding: 0 0 0 0.57em;
			list-style: none;
		}
	}

	&__kontakt {
		margin-left: 0.57em;
	}

	&__seperator {
		margin: 0 -0.71em;
		padding: 0 0.71em;
		border-top: 0.07em solid $color_middlegrey;
	}

	&__notizen {
		margin-top: 1.43em;
	}
}
