@use '@angular/material' as mat;

.c-register-container {
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.c-register {
	display: block;
	width: 100%;
	height: calc(100vh - 11.4em);
	box-shadow: 0 0 0.43em 0.07em rgba(#000, 0.12);
	background: #fff;
	overflow: hidden;
	margin: 2.29em 1.14em;

	&__content {
		display: flex;
		flex-direction: column;
		min-height: 0; /* for Firefox */
		height: 100%;
		position: relative;
	}

	&__header {
		height: 100%;

		.mat-mdc-tab-group {
			height: calc(100% - 4em);
		}

		.mat-mdc-tab-body-wrapper {
			height: 100%;

			mat-mdc-tab-body {
				.mat-tab-body-content {
					overflow-x: hidden;
				}
			}
		}

		h2 {
			margin: 0 !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__headline {
		background: mat.get-color-from-palette($primary, darker);
		color: mat.get-color-from-palette($primary, default-contrast);
		display: flex;
		justify-content: left;
		align-items: center;
		padding: 0 1.71em;
		height: 4em;

		i {
			margin-right: 0.86em;
		}

		&--with-buttons {
			justify-content: space-between;
		}
	}

	.c-register-column-button {
		min-width: 1.71em;
		width: 1.71em;
		height: 1.71em;
		line-height: 1.71em;
		box-shadow: 0 0.14em 0.29em 0 rgba(#000, 0.5);

		mat-icon {
			font-size: 1.5em;
		}
	}
}
