@use "sass:math";

.c-table-list {
	box-shadow: 0 0.07em 0.86em rgba($color_black, 0.3);
	border-top: 0.21em solid $color_blue;
	border-spacing: 0;
	width: 100%;

	&--orange {
		border-color: $color_orange;
	}

	&--yellow {
		border-color: $color_yellow;
	}

	&__header {
		background: $color_lightgrey;

		.mat-h2 {
			margin: 0;
		}
	}

	td.mat-cell,
	th.mat-header-cell {
		padding: 0.71em !important;
	}

	tbody {
		height: 20.71em;
		overflow-y: auto;
		width: 100%;
	}

	thead,
	tbody,
	td,
	th {
		display: block;
	}

	thead {
		min-height: 3.64em;
	}

	tr {
		overflow: hidden;
		height: auto !important;
		display: flex;
		width: 100%;
	}

	td,
	th {
		float: left;
		width: 100%;
		min-height: 3em;
	}

	&--with-6-columns {
		td,
		th {
			width: percentage(math.div(1,6));
		}

		.c-table-cell {
			&--small {
				width: percentage(math.div(math.div(1,6), 2));
			}

			&--big {
				width: percentage(math.div(math.div(1,6),2) * 3);
			}
		}
	}

	&--with-2-columns {
		td,
		th {
			width: percentage(math.div(1,3));
		}
	}

	&--with-3-columns {
		td,
		th {
			width: percentage(math.div(1,3));
		}
	}

	&--with-10-columns {
		td,
		th {
			width: 10%;
		}
	}
}
