@use "sass:math";

.l-container {
	margin: 0;
	display: block;
	max-width: 134.71em;
	overflow-x: auto;
	padding: 0 1.14em;

	@include breakpoint($xs) {
	}

	@include breakpoint($medium) {
	}

	@include breakpoint($large) {
		margin: 0 auto;
	}
}

.l-grid {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&--row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&--justify-end {
		display: flex;
		justify-content: flex-end;
	}

	&__item {
		padding: 0 0.71em;
		margin-bottom: 5.71em;

		&--thirds {
			width: percentage(math.div(1,3));
			max-width: 31.71em;
		}

		&--two-thirds {
			width: percentage(math.div(2,3));
			max-width: 71.36em;
		}
	}
}

$grid-columns: 17;

@for $i from 1 through $grid-columns {
	.l-grid--#{$i} {
		width: #{$i * 90}px;
	}
}

[class^='l-grid--'],
[class*=' l-grid--'] {
	box-sizing: border-box;
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&__item-main {
		flex-grow: 1;
	}

	&__item-side {
		width: 31.43em;
		flex-grow: 0;
		flex-shrink: 0;
	}

	&--centered {
		align-items: center;
	}
}

.flex-column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
