.c-aufgabe {
	background: $color_middlegrey;
	margin: -0.71em -0.71em 1.43em -0.71em;
	padding: 0.36em 0.71em;
	position: relative;

	&__typ {
		margin-bottom: 0.36em;
	}

	&__kontakt-liste {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__checkbox {
		position: absolute;
		right: 0.79em;
		top: 0.43em;

		.mat-checkbox-inner-container {
			background: #fff;
		}
	}
}
