.c-input {
	line-height: 2em;
	padding: 0 0.71em;
	font-size: 1rem;
	border: 0.07em solid $color_darkgrey;
	border-radius: 0.29em;
	width: 100%;

	&:focus {
		border: 0.07em solid $color_blue;
		outline: 0;
	}
}
