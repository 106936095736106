.c-addresses {
	border-bottom: 0.07em solid $color_middlegrey;
	padding: 0;
	margin-bottom: 1.43em;
	padding-bottom: 0.36em;

	&__headline {
		margin-bottom: 0.36em
	}

	&__list {
		margin: 0 0 0.36em 0;
		padding: 0;
		list-style: none;
	}

	&__address {
		padding: 0.21em 0;
		margin: 0 -0.43em;
		border-bottom: 0.07em solid $color_middlegrey;
		display: flex;
		justify-content: space-between;

		&:last-child {
			border-bottom: 0;
		}

		i {
			font-size: 1.29em;
			padding: 0.43em;
		}

		p {
			margin: 0.21em 0;
		}

		&--plain {
			padding: 0.21em 0;
			margin: 0;
			position: relative;
			border-bottom: 0.07em solid $color_middlegrey;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&__data {
		flex-grow: 1;
	}

	&__add-button {
		margin: 0.71em 0;
	}
}
