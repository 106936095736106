schir-int-client-register-rahmen {
	.mat-form-field-infix {
		width: 100% !important;
		// margin: -0.71em 0 -0.14em;
		padding: 0.64em 0.29em !important;
		background: $color_middlegrey;
		border-top: 0.07em solid $color_darkgrey;
	}

	mat-form-field {
		width: 100%;
	}

	.mat-form-field-wrapper {
		margin-bottom: -1.21em;
	}
}

// fix material input height
.registerColumnComp {
	.mat-input-element {
		height: 1.29em;
	}
}
