.c-row {
	display: flex;
	justify-content: space-between;
	color: rgba(#000, 0.87);
	line-height: 1.4;

	&--header {
		font-size: 1.14em;
		.c-row__item {
			&--header {
				font-weight: 500;
			}
			&--secondary {
				color: rgba(#000, 0.6);
			}
		}
	}

	&--secondary {
		color: rgba(#000, 0.6);
	}

	&__item {
		white-space: nowrap;
		font-size: inherit;
		margin: 0 !important;

		&:first-child {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&--multiline {
			white-space: normal;
		}
	}
}

.x-section__headline {
	.c-row {
		color: rgba(#fff, 1);

		.c-row__item--secondary {
			color: rgba(#fff, 0.87);
		}
	}
}

.c-wrap {
	flex-wrap: wrap;
}
