.c-button-bar {
	display: flex;
	justify-content: flex-end;
	margin: 0.29em;
	opacity: 0;

	&--overlay {
		position: absolute;
		bottom: 0;
		right: 10px;
	}
}

.c-list__item {
	.c-button-bar {
		opacity: 0;
	}

	&--active,
	&:hover,
	&:focus-within {
		.c-button-bar {
			opacity: 1;
		}
	}
}

schir-int-client-register-column,
schir-int-client-register-column-entries {
	&:hover {
		.c-button-bar {
			opacity: 1;
		}
	}
}

schir-int-client-belastung-entry,
schir-int-client-veraenderungen-hypotheken-entry,
schir-int-client-register-multicolumn-entry,
schir-int-client-eigentuemer-schiffsparten-entry {
	&:focus-within {
		.c-button-bar {
			opacity: 1;
		}
	}
}

.mat-row {  // TODO diese Variante weg, wenn alle rows mat-mdc-row sind!
	.c-button-bar {
		opacity: 0;
	}

	&--active,
	&:hover,
	&:focus-within {
		.c-button-bar {
			opacity: 1;
		}
	}
}

.mat-mdc-row {
	.c-button-bar {
		opacity: 0;
	}

	&--active,
	&:hover,
	&:focus-within {
		.c-button-bar {
			opacity: 1;
		}
	}
}
