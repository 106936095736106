.c-registertabelle {
	border-collapse: collapse;
	border-spacing: 0;
	border: 0;
	font-size: 0.875rem;
	height: 0.07em; // workaround for 100% height div in 100% height table-cell
	overflow: hidden;
	color: $color_black;
	margin: 0.86em;
	width: calc(100% - 1.71em);

	thead {
		background: $color_middlegrey;
		margin: -0.07em;

		th {
			vertical-align: middle;
			text-align: center;
			border: 0.07em solid #fff;
			padding: 0.36em;

			&:first-child {
				border-left: 0.07em solid $color_middlegrey;
			}

			&:last-child {
				border-right: 0.07em solid $color_middlegrey;
			}
		}

		tr:first-child {
			th {
				border-top: 0.07em solid $color_middlegrey;
			}
		}
	}

	td {
		vertical-align: top;
		text-align: center;
		border: 0.07em solid #EDEDED;
		padding: 0.36em;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		margin: -0.43em;
		width: calc(100% + 0.86em);
	}
}
