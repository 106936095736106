.c-verfahrensbaum {
	height: 100%;

	&__list {
		list-style: none;
		margin: 1.43em 0;
		padding: 0 2.86em;

		li {
			position: relative;
			margin: 0;
			padding: 0 1.43em;

			&:before {
				position: absolute;
				left: 0.36em;
				content: '-';
				display: block;
			}
		}
	}

	&__vorgaenge {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 0.9rem;

		li {
			margin: 0 1.43em;
			padding: 0 1.43em;
			white-space: nowrap;
			cursor: pointer;

			&:hover {
				background: $color_lightblue;
			}
		}
	}

	&__headline {
		margin-bottom: 0.14em;
		display: flex;
		justify-content: space-between;
		white-space: nowrap;
		align-items: center;
	}

	.mat-icon-button {
		line-height: 1.43em;
		height: 1.43em;
		width: 1.43em;
		font-size: 0.93em;
		border: 0.07em solid;
	}
}
