.c-register-sheet {
	border-collapse: collapse;
	border-spacing: 0;
	border: 0.14em solid black;
	font-size: 0.875rem;
	height: 0.07em; // workaround for 100% height div in 100% height table-cell

	td,
	th {
		vertical-align: middle;
		text-align: center;
		border: 0.07em solid black;
		padding: 0.36em;
	}

	td {
		text-align: left;
	}

	table {
		border: 0;
		margin: -0.43em;
		width: calc(100% + 0.86em);
	}

	&__dynamic-column {
		padding: 0 !important;
	}

	schir-int-client-register-column {
		display: block;
		height: 100%;
	}
}
