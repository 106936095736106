.c-button {
	outline: 0;
	background: transparent;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;
	text-decoration: none;

	&:disabled {
		cursor: not-allowed;
		pointer-events: none;
		display: none;
	}

	&--in-entry {
		opacity: 0;
	}

	&--round,
	&--rounded {
		min-width: 2em;
		height: 2em;
		border-radius: 1.71em;
		background-color: $color-mat-primary;
		margin: 0 0.29em;
		padding: 0;
		outline: 0;
		cursor: pointer;
		color: #fff;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0.14em 0.29em 0 rgba(#000, 0.5);
		border: 0.07em solid $color-mat-primary;

		&:focus-within {
			border-color: #fff;
		}

		&:hover,
		&:active {
			background-color: #fff;
			border: 0.07em solid $color-mat-primary;
			color: $color-mat-primary;
		}

		i {
			font-size: 1.43em;

			&.icon-small {
				font-size: 1.29em;
			}

			&.icon-small-up {
				font-size: 1.29em;
				margin-top: -0.21em;
			}
		}
	}

	&--rounded {
		padding: 0 0.57em;
	}
}

.c-registerblatt {
	.c-button--round {
		min-width: 1.71em;
		width: 1.71em;
		height: 1.71em;
		line-height: 1.71em;
		border-radius: 0.86em;

		i {
			font-size: 1.36em;

			&.icon-small {
				font-size: 1.14em;
			}

			&.icon-small-up {
				font-size: 1.14em;
			}
		}
	}
}

schir-int-client-register-column,
schir-int-client-register-column-entries {
	&:hover {
		.c-button--in-entry {
			opacity: 1;
		}
	}
}

.c-tab-head-button-with--icon {
	color: #fff;
	font-size: 1.14em;
	line-height: 2.29em;
	border-radius: 1.14em;
	border: 0.07em solid $color-mat-primary-dark;

	&:focus-within {
		border-color: #fff;
		background-color: rgba(#000, 0.1);
	}

	&:hover {
		background: #fff;
		color: $color-mat-primary-dark;
	}
}

.c-register-container {
	.mat-mini-fab {
		.mat-icon {
			font-size: 1.36em;

			&.small-icon {
				font-size: 1.21em;
			}
		}

		i {
			font-size: 1.29em;

			&.small-icon {
				font-size: 1.14em;
			}
		}
	}

	.aufschrift__headline,
	.header {
		.mat-mini-fab {
			background-color: transparent;
			box-shadow: none;
		}

		.mat-button-wrapper {
			color: #000000;
		}
	}
}

body .mat-mini-fab {
	$size: 2.29em;

	width: $size;
	height: $size;
	line-height: $size;
	margin: 0 0.14em;

	.mat-icon {
		height: 1em;
	}

	.mat-button-wrapper {
		padding: 0;
	}

	i {
		font-size: 1.57em;

		&.small {
			font-size: 1.43em;
		}
	}
}
