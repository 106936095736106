.c-kontakt {
	margin: 0.36em 0 !important;
	border-radius: 0 !important;
	box-shadow: none !important;

	&__header {
		padding: 0.36em 1.86em !important;
	}

	&__title {
		display: flex;
		justify-content: space-between;
		margin-right: 0 !important;
	}

	&__kategorie,
	&__kanal {
		width: 30%;
		padding-right: 0.36em;
	}

	&__data {
		width: 70%;
	}

	&__expansion {
		display: flex;
		flex-direction: column;
	}

	&__text {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	&__buttons {
		margin: 0.43em -0.79em 0.21em 6.71em;

		button {
			width: 100%;
		}
	}

	.mat-expansion-panel-body {
		padding: 0 1.86em 0.5em;
		margin-right: 0.57em;
	}
}
