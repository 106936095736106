// https://material.io/tools/color/#!/?view.left=1&view.right=0&primary.color=1564bf&secondary.color=F9A825
// material colors: https://stackoverflow.com/questions/47497743/how-to-create-a-custom-color-theme-with-angular5-and-angular-materials
@use '@angular/material' as mat;
@import "../material-theming/theme";


$color_black: #202020;
$color_darkgrey: #747474;
$color_middlegrey: #EDEDED;
$color_lightgrey: #FAFAFA;
$color_white: #ffffff;
$color_blue: #1359c3;
$color_orange: #D8132B;
$color_yellow: #ffbc42;
$color_orangelight: #ffdfd3;
$color_lightblue: #d8e7fc;

$color_background-grey: #ECECEC;
$color_register-border: #DDEEFC;

$color-mat-primary: mat.get-color-from-palette($primary);
$color-mat-primary-dark: mat.get-color-from-palette($primary, darker);
$color-mat-primary-light: mat.get-color-from-palette($primary, lighter);
$color-warn: mat.get-color-from-palette($warn);

$color-mat-accent: mat.get-color-from-palette($accent);
$color-mat-accent-dark: mat.get-color-from-palette($accent, darker);
