/*$roboto-font-path: '~roboto-fontface/fonts';
@import '~roboto-fontface/css/mixins';

@include roboto-font('roboto', 'Roboto', 'Regular', 400, normal)*/

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src:url(../../assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.71em;
  line-height: 0.9em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
