.c-registerblatt {
	display: grid;
	grid-gap: 0.07em;
	height: 100%;

	label {
		white-space: normal;
		hyphens: auto;
		overflow-wrap: break-word;
	}

	> div {
		white-space: nowrap;
		background-color: $color-mat-primary-light;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0.29em;
		text-align: center;

		> span {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			text-align: center;
		}
	}

	&__edit-column {
		background-color: #fff;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.row-span,
	.row-span-2 {
		grid-row: span 2;
	}

	.row-span-3 {
		grid-row: span 3;
	}

	.row-span-4 {
		grid-row: span 4;
	}

	.col-span, .col-span-2 {
		grid-column: span 2;
	}

	.col-span-3 {
		grid-column: span 3;
	}

	.col-span-5 {
		grid-column: span 5;
	}

	.column-span {
		grid-column: span 2;
	}

	.multi-column-column {
		display: flex;
		white-space: pre-line;
		position: relative;
		width: 100%;

		> schir-int-client-register-multicolumn-value:last-of-type {
        	width: 100%;
        	> .column--right {
        		height: 100%;
        		> .c-entry {
							height: 100%;
						}
        	}
      }
	}
}
