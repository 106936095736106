.l-align {
	&--left {
		justify-content: left;
	}

	&--right {
		justify-content: flex-end;
		display: flex;
	}

	&--space-between {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
