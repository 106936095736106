.x-sectioncontainer {
	display: flex;
}

.x-section-hidden {
	min-width: 0 !important;
	max-width: 0 !important;
	width: 0 !important;
}

.x-section {
	width: calc((100vw - 7.5em) / 5);
	min-width: 15em;
	max-width: 25.71em;
	height: 100%;
	float: left;
	border-right: 0.07em solid $color-background-grey;
	display: flex;
	flex-direction: column;
	box-sizing: content-box;

	&__container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&:last-child {
		border-right: 0;
	}

	&__headline,
	&__subline {
		padding: 0 1.71em;
		display: flex;
		justify-content: space-between;
		color: #fff;
		align-items: center;
		outline: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		flex-shrink: 0;

		> i {
			margin-right: 0.57em;
		}

		.mat-body,
		.mat-h1 {
			margin: 0 !important;
			overflow: hidden;
			text-overflow: ellipsis;
			flex-grow: 1;
		}


	}

	&__subline {
		padding: 0 0.14em 0 1.71em;

		.mat-mini-fab {
			box-shadow: none;
		}
	}

	&__headline {
		background-color: $color-mat-primary-dark;
		height: 4em;
	}

	&__subline {
		background-color: $color-mat-primary;
		height: 2.29em;

		i {
			font-size: 1.43em;
		}

		button {
			margin: 0 0.29em 0 0.29em;
			box-shadow: none;

			&:disabled {
				opacity: 0.3;
				display: flex;
			}
		}
	}

	&__main {
		flex-grow: 1;
		overflow: hidden;
		position: relative;
	}

	&--accent {
		.x-section {
			&__headline {
				background-color: $color-mat-accent-dark;
				color: #000;
			}
			&__subline {
				background-color: $color-mat-accent;
				color: #000;

				.mat-mini-fab {
					background-color: transparent;
				}
				.mat-button-wrapper {
					color: #000;
				}
			}
		}
	}
}
