.c-spinner {
	width: 100%;
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	background: #fff;

	&--visible {
		display: flex;
	}
}
