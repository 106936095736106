.c-list {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
	overflow: auto;

	&__item {
		position: relative;
		cursor: pointer;
		box-shadow: inset 0 1px 0 0 $color-background-grey, inset 0 -1px 0 0 $color-background-grey;

		&:hover {
			background-color: rgba(#000, 0.06);
		}

		&--active {
			background-color: rgba(#000, 0.12) !important;
		}

		&--note {
			margin-left: 0;
			padding: 0.57em 1.14em 0.57em 1.71em;
			border-bottom: 0;
			border-top: 0.07em solid $color-background-grey;
			margin-top: -0.07em;

			&:hover,
			&:focus-within {
				background-color: inherit;
			}
		}
	}

	&__link {
		position: relative;
		display: flex;
		flex-direction: column;
		color: inherit;
		text-decoration: none;
		padding: 0.43em 1.71em;
		margin: 0;
		outline: 0;
		min-height: 5.14em;
	}

	&__icon {
		position: absolute;
		right: 0;
		top: 40%;
		transform: translateY(-50%);
	}
}
